@import '../../style/app.scss';

.successorder{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: Poppins;

    .container{
        text-align: center;
    }
    
    .title{
        font-size: 40px;
        font-weight: 600;
    }

    .message1{
        font-size: 30px; /* general fallback */
        font-size: 5vm; /* IE9 fallback */
        font-size: 5vmin;
    }

    .message2{
        font-size: 15px;
    }

    .button{
        width:15em;
        height:3em;
        background-color: $base-color;
        border:0;
    }


}