@font-face {
  font-family: poppins-bold;
  src: url("../../assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: poppins;
  src: url("../../assets/fonts/Poppins-Regular.ttf") format("truetype");
}

.card-menu{
    width: 100%;
}

.navbar-seafood{
  background-color: #ee4d2d;
  text-align: center;
  height:50px;
}

.navbar-brand{
  font-family: poppins-bold;
  color: white !important;
  padding:0px;
  margin-top: 6px;
  font-size: 25px;
}

.thumb-post img {
    object-fit: none; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    width: 100%;
    max-height: 250px;
    margin-bottom: 1rem;
  }

.item-image {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 12px;
  border: 3px solid white;
}

.item-text{
    font-size:12px;
    color:black;
    font-family: poppins;
}

.item-title{
  font-size: 17px;
  color: black;
}

.item-card{
  background-color: #ede2d6;
  /* box-shadow: 0 0 2px 0 #f2cca4; */
  font-family : poppins-bold;
  border-radius: 12px;
  height:150px;
}

.item-list-container{
  background-color: #ffffff;
  padding-bottom: 80px;
}

.item-navbar{
  background-color: #ee4d2d;
}

.item-button-input-group{
  width: 125px;
}

.item-price-text{
  font-family : poppins-bold;
  color: black;
  font-size: 23px;
}

.item-price-text-prefix{
  font-family : poppins-bold;
  color: black;
  font-size: 18px;
}

.card-price-row{
  height: 100px;
}

.item-text-weight{
  font-size: 15px;
  width:70px;
  background-color:#ee4d2d;
  border-radius: 5px;
  text-align: center;
  color:white;
}

.item-text-header{
  font-size: 12px;
  margin-right: 5px;
}

.card-price{
  margin-top: -40px;
}

.item-card-footer{
  height: 75px;
  background-color:white;
  border-top-width: 4px !important;
  z-index: 0 !important;
}

.item-footer-button{
  font-family: poppins;
  font-size: 1em;
  height: 45px;
  width: 120px;
  margin-right: 5px;
  border-radius: 30px;
  margin-top: 5px;
  background-color: #ee4d2d;
  color: white;
}

.item-card-btm-col-3{
  background-color: #ee4d2d;
  font-family: poppins;
  font-size:15px;
}

.footer-container{
  width: 170px;
  height: 100%;
  padding-bottom: 10px;
}

.footer-price-total-prefix{
  font-family: poppins;
  font-size: 18px;
}

.footer-price-total{
  font-family: poppins-bold;
  font-size: 20px;
}

.footer-text-left{
  font-family: poppins;
  height: 100%;
  width: 35px;
  border-radius: 0px;
  text-align: center !important;
  background-color: #ee4d2d;
  padding-top: 25px;
  color:white;
}