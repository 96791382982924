@import '../../../style/app.scss';

.form{
    //width:350px;
    width:90%;

    .title{
        font-family: Poppins, sans-serif;
        margin-bottom: 0;
        Color: white;
    }
}