@import '../../../style/app.scss';

.card{
    border:none;
    margin-bottom: 10px;
    height:60px;
    
    .body{
        padding: 10px;
    }

    .quantity{
        text-align: center;
        //background-color: #bbbec5;
        background-color: $base-color;
        border-radius: 5px;
        color:white;
    }

    .row{
        height:20px;
    }

    .col{
        font-size: 15px;
        height:1.5em;
    }
}