
@import '../../style/app.scss';

.cardlist{
    //width: 350px;
    width:90%;
    
    .cardbody{
        height:300px;
    }

    .container_top{
        height:170px;
        overflow-y: auto;
        padding-left: 0;
        padding-right: 0;
    }
    
    .container_bottom{
        padding-left: 0;
        padding-right: 0;
    }

    .title{
        font-family: Poppins, sans-serif;
        margin-bottom: 0;
        Color: white;
    }

    .card{
        padding:0;
        background-color: #f8f9fe;
    }
}