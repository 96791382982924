@import '../../style/app.scss';

.checkoutpage{
    background-color: $base-color;
    font-family: Poppins;
    width:100%;
    min-height: 100vh;

    .header{
        color:white;
        padding-top: 20px;
        text-align: center;
        font-size: 30px;
    }

    .buttonSubmit{
        height:55px;
        width:90%;
        margin-bottom: 20px;
    }
}