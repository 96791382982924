@import '../../../style/app.scss';

.button{
	background-color: #278cff; 
    color:white;
	border: 0; 
	outline: 0; 
	padding: 1rem; 
    text-align: center;
	font-size: 1rem; 
	font-family: poppins; 
	box-shadow: 0px 5px 10px #0057ab; 
	transition: all 0.3s; 
	cursor: pointer; 
	border-radius: 5px; 
	border-bottom: 4px solid #d9d9d9; 
    margin-bottom: 20px;
	
    &:hover{
		box-shadow: 0px 15px 25px -5px #0057ab; 
		transform: scale(1.03); 
    }
	&:active{
		box-shadow: 0px 4px 8px #0065c8; 
		transform: scale(0.98); 
    }
}