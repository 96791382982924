@font-face {
    src: url("/assets/fonts/Poppins-Bold.ttf") format("truetype");
    font-family: poppins-bold;
}

@font-face {
    src: url("/assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-family: poppins;
}

$base-color: #ee4d2d;