@import '../../../style/app.scss';

.confirmation{
    background-color: white;
    height:200px;
    width:300px;
    padding: 20px 0px 20px 0px;

    .confirm_header{
        text-align: center;
        font-size: 28px;
        font-family: poppins-bold;
        color:$base-color;
    }

    .confirm_text{
        font-family: poppins;
        font-size: 15px;
        padding: 0px 10px 0px 10px;
        text-align: center;
    }

    .confirm_button{
        font-family: poppins;
        border: 1px solid;
        width: 100px;
        margin-left: 5px;
        margin-right: 5px;
    }
}