.photocarousel {
    height: 380px;
    width: 380px;
    background-color: white;

    .image{
        height: 380px;
        width: 380px;
    }
    
    .topbar{
        padding: 0px 10px 0px 10px;
    }

    .topbartext{
        font-size: 20px;
        padding:0;
    }
}