body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: poppins-bold;
  src: url(/static/media/Poppins-Bold.a3e0b5f4.ttf) format("truetype");
}

@font-face {
  font-family: poppins;
  src: url(/static/media/Poppins-Regular.8b6af8e5.ttf) format("truetype");
}

.card-menu{
    width: 100%;
}

.navbar-seafood{
  background-color: #ee4d2d;
  text-align: center;
  height:50px;
}

.navbar-brand{
  font-family: poppins-bold;
  color: white !important;
  padding:0px;
  margin-top: 6px;
  font-size: 25px;
}

.thumb-post img {
    object-fit: none; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    width: 100%;
    max-height: 250px;
    margin-bottom: 1rem;
  }

.item-image {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 12px;
  border: 3px solid white;
}

.item-text{
    font-size:12px;
    color:black;
    font-family: poppins;
}

.item-title{
  font-size: 17px;
  color: black;
}

.item-card{
  background-color: #ede2d6;
  /* box-shadow: 0 0 2px 0 #f2cca4; */
  font-family : poppins-bold;
  border-radius: 12px;
  height:150px;
}

.item-list-container{
  background-color: #ffffff;
  padding-bottom: 80px;
}

.item-navbar{
  background-color: #ee4d2d;
}

.item-button-input-group{
  width: 125px;
}

.item-price-text{
  font-family : poppins-bold;
  color: black;
  font-size: 23px;
}

.item-price-text-prefix{
  font-family : poppins-bold;
  color: black;
  font-size: 18px;
}

.card-price-row{
  height: 100px;
}

.item-text-weight{
  font-size: 15px;
  width:70px;
  background-color:#ee4d2d;
  border-radius: 5px;
  text-align: center;
  color:white;
}

.item-text-header{
  font-size: 12px;
  margin-right: 5px;
}

.card-price{
  margin-top: -40px;
}

.item-card-footer{
  height: 75px;
  background-color:white;
  border-top-width: 4px !important;
  z-index: 0 !important;
}

.item-footer-button{
  font-family: poppins;
  font-size: 1em;
  height: 45px;
  width: 120px;
  margin-right: 5px;
  border-radius: 30px;
  margin-top: 5px;
  background-color: #ee4d2d;
  color: white;
}

.item-card-btm-col-3{
  background-color: #ee4d2d;
  font-family: poppins;
  font-size:15px;
}

.footer-container{
  width: 170px;
  height: 100%;
  padding-bottom: 10px;
}

.footer-price-total-prefix{
  font-family: poppins;
  font-size: 18px;
}

.footer-price-total{
  font-family: poppins-bold;
  font-size: 20px;
}

.footer-text-left{
  font-family: poppins;
  height: 100%;
  width: 35px;
  border-radius: 0px;
  text-align: center !important;
  background-color: #ee4d2d;
  padding-top: 25px;
  color:white;
}
.PhotoCarousel_photocarousel__1qfEH {
  height: 380px;
  width: 380px;
  background-color: white;
}
.PhotoCarousel_photocarousel__1qfEH .PhotoCarousel_image__3mxcL {
  height: 380px;
  width: 380px;
}
.PhotoCarousel_photocarousel__1qfEH .PhotoCarousel_topbar__hIziL {
  padding: 0px 10px 0px 10px;
}
.PhotoCarousel_photocarousel__1qfEH .PhotoCarousel_topbartext__3QRdh {
  font-size: 20px;
  padding: 0;
}
.swiper-container {
    width: 380px;
    height: 380px;
}
.btn-circle-add {
    height: 40px;
    font-size: 25px;
    border-radius: 50%;
}
@font-face {
  src: url("/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-family: poppins-bold;
}
@font-face {
  src: url("/assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-family: poppins;
}
.Form_form__2mqGz {
  width: 90%;
}
.Form_form__2mqGz .Form_title__leHVf {
  font-family: Poppins, sans-serif;
  margin-bottom: 0;
  Color: white;
}
@font-face {
  src: url("/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-family: poppins-bold;
}
@font-face {
  src: url("/assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-family: poppins;
}
.Card_card__3Ya2a {
  border: none;
  margin-bottom: 10px;
  height: 60px;
}
.Card_card__3Ya2a .Card_body__qxR83 {
  padding: 10px;
}
.Card_card__3Ya2a .Card_quantity__2KbZJ {
  text-align: center;
  background-color: #ee4d2d;
  border-radius: 5px;
  color: white;
}
.Card_card__3Ya2a .Card_row__2Lod5 {
  height: 20px;
}
.Card_card__3Ya2a .Card_col__3q4cR {
  font-size: 15px;
  height: 1.5em;
}
.Summaryrow_summaryrow__2QqTS {
  height: 22px;
  color: #a1a3a9;
}
@font-face {
  src: url("/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-family: poppins-bold;
}
@font-face {
  src: url("/assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-family: poppins;
}
.Cardlist_cardlist__1Pu09 {
  width: 90%;
}
.Cardlist_cardlist__1Pu09 .Cardlist_cardbody__3JqJv {
  height: 300px;
}
.Cardlist_cardlist__1Pu09 .Cardlist_container_top__jWwKb {
  height: 170px;
  overflow-y: auto;
  padding-left: 0;
  padding-right: 0;
}
.Cardlist_cardlist__1Pu09 .Cardlist_container_bottom__1jtvo {
  padding-left: 0;
  padding-right: 0;
}
.Cardlist_cardlist__1Pu09 .Cardlist_title__sqD0X {
  font-family: Poppins, sans-serif;
  margin-bottom: 0;
  Color: white;
}
.Cardlist_cardlist__1Pu09 .Cardlist_card__3s7j8 {
  padding: 0;
  background-color: #f8f9fe;
}
@font-face {
  src: url("/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-family: poppins-bold;
}
@font-face {
  src: url("/assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-family: poppins;
}
.Button_button__1HV2c {
  background-color: #278cff;
  color: white;
  border: 0;
  outline: 0;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  font-family: poppins;
  box-shadow: 0px 5px 10px #0057ab;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  border-bottom: 4px solid #d9d9d9;
  margin-bottom: 20px;
}
.Button_button__1HV2c:hover {
  box-shadow: 0px 15px 25px -5px #0057ab;
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.Button_button__1HV2c:active {
  box-shadow: 0px 4px 8px #0065c8;
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}
@font-face {
  src: url("/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-family: poppins-bold;
}
@font-face {
  src: url("/assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-family: poppins;
}
.Confirmation_confirmation___nM4T {
  background-color: white;
  height: 200px;
  width: 300px;
  padding: 20px 0px 20px 0px;
}
.Confirmation_confirmation___nM4T .Confirmation_confirm_header__3uENA {
  text-align: center;
  font-size: 28px;
  font-family: poppins-bold;
  color: #ee4d2d;
}
.Confirmation_confirmation___nM4T .Confirmation_confirm_text__1iFwW {
  font-family: poppins;
  font-size: 15px;
  padding: 0px 10px 0px 10px;
  text-align: center;
}
.Confirmation_confirmation___nM4T .Confirmation_confirm_button__3NKX- {
  font-family: poppins;
  border: 1px solid;
  width: 100px;
  margin-left: 5px;
  margin-right: 5px;
}
@font-face {
  src: url("/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-family: poppins-bold;
}
@font-face {
  src: url("/assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-family: poppins;
}
.CheckoutPage_checkoutpage__1M2_m {
  background-color: #ee4d2d;
  font-family: Poppins;
  width: 100%;
  min-height: 100vh;
}
.CheckoutPage_checkoutpage__1M2_m .CheckoutPage_header__WfczY {
  color: white;
  padding-top: 20px;
  text-align: center;
  font-size: 30px;
}
.CheckoutPage_checkoutpage__1M2_m .CheckoutPage_buttonSubmit__21BGO {
  height: 55px;
  width: 90%;
  margin-bottom: 20px;
}
@font-face {
  src: url("/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-family: poppins-bold;
}
@font-face {
  src: url("/assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-family: poppins;
}
.SuccessOrderPage_successorder__3Oj5m {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Poppins;
}
.SuccessOrderPage_successorder__3Oj5m .SuccessOrderPage_container__1NkbT {
  text-align: center;
}
.SuccessOrderPage_successorder__3Oj5m .SuccessOrderPage_title__3ENfV {
  font-size: 40px;
  font-weight: 600;
}
.SuccessOrderPage_successorder__3Oj5m .SuccessOrderPage_message1__2JeJn {
  font-size: 30px; /* general fallback */
  font-size: 5vm; /* IE9 fallback */
  font-size: 5vmin;
}
.SuccessOrderPage_successorder__3Oj5m .SuccessOrderPage_message2__PoE16 {
  font-size: 15px;
}
.SuccessOrderPage_successorder__3Oj5m .SuccessOrderPage_button__2GDRf {
  width: 15em;
  height: 3em;
  background-color: #ee4d2d;
  border: 0;
}
